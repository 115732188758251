@import '~@beewise/theme/build/variables';

.bhome-layout-wrapper {
    .bhome-layout-toggler {
        display: flex;
        border: 1px solid $gray-01;
        padding: 4px 8px;
        border-radius: 4px;
        cursor: pointer;
        max-width: 52px;

        @import 'src/components/reusables/BhomeLayout/components/Frame/FrameColors';

        .full {
            min-height: 24px;
            min-width: 4px;
        }

        .full + .full {
            margin-left: 2px;
        }
    }
}

@import '~@beewise/theme/build/variables';
@import '~@beewise/theme/build/reusables';

.app-header {
    background-color: $black;
    height: $app-header-height;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 15px;
    box-sizing: border-box;
    position: fixed;
    width: 100%;
    z-index: 999;

    .logo-container {
        padding: 10px 0 10px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
    }

    .nav-list {
        margin-left: 75px;

        .btn {
            display: inline-block;
            padding: 0;
            color: $dark-grey;
            background-color: transparent;
            border: none;
        }

        a {
            color: $dark-grey;
            text-decoration: none;

            &.active {
                font-weight: 700;
                color: $white;
            }
        }

        &-button {
            font-size: 14px;
        }

        &-disabled {
            pointer-events: none;
        }

        a + a {
            margin-left: $double-spacing;
        }

        a + button {
            margin-left: $double-spacing;
        }
    }

    .ssh {
        display: flex;
        flex-direction: row-reverse;
        margin-right: $spacing;
        align-items: center;

        .text-field-root {
            margin: 0 10px;
        }

        &-info {
            color: $white;
            margin: 0 10px;
            font-size: 12px;
            cursor: pointer;

            .icon-copy {
                color: $white;
                padding: 0 5px;
            }
        }

        input {
            width: 60px;
        }
    }

    .main-menu {
        margin-left: auto;
        display: flex;
        align-items: center;

        .select-field-root {
            width: 180px;
            margin-right: 5px;

            .select-field-label {
                height: 100%;
            }
        }
        .beehome-select {
            width: 200px;
        }

        .bhome-remover {
            margin-left: 15px;
            margin-right: 46px;
            background-color: $white;
            padding-top: 7px;
            padding-bottom: 7px;

            &-hidden {
                opacity: 0;
            }
        }

        .stop {
            margin-right: $spacing;
            padding: 10px 12px;
        }

        .icon + .icon {
            margin-left: 46px;
        }

        .activity {
            path {
                stroke: $white;
            }

            cursor: pointer;

            &[disabled] {
                path {
                    stroke: $dark-grey;
                }

                user-select: none;
            }
        }

        .user-white {
            path {
                stroke: $white;
            }
        }

        button.icon {
            padding: 0;
            background-color: transparent;
        }
    }

    .side-panel-toggler {
        display: none;
    }

    .app-env {
        margin-left: $spacing;
        border-radius: 4px;
        padding: 4px 8px;
        text-transform: uppercase;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: $black;

        &.dev,
        &.lab {
            background-color: $off-white;
        }

        &.stage {
            background-color: $light-blue;
        }

        &.prod {
            background-color: #ff1493;
        }
    }
    .bhome-online {
        background-color: $white;
        margin-right: $spacing;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 8px;
        border-radius: 8px;

        &-dot {
            width: 16px;
            height: 16px;
            background-color: $bad;
            border-radius: 50%;
            margin-right: 8px;

            &.is-online {
                background-color: $good;
            }
        }
    }

    .beehome-status {
        margin: 0 10px;

        .icon.reception,
        .icon.power,
        .status-icon {
            display: none;
        }

        .voltage {
            width: 18px;
            height: 18px;
        }

        .voltage:before {
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.profile {
    background-color: $grey;
    padding: $spacing;
    display: flex;
    flex-direction: column;

    &-sub {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-form {
        @extend .white-box;
        padding: $double-spacing $spacing $spacing;
        margin-top: $spacing;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    &-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: $spacing;

        .reset-password {
            color: $dark-grey;
        }

        .logout {
            color: $bad;
        }
    }

    /* TODO: remove once content will be added to this section */
    .notifications-form {
        padding: 128px $spacing;
    }

    .input + .input {
        margin-top: 50px;
    }

    &-switch {
        rect:first-of-type {
            fill: $dark-grey;
        }
        rect:last-of-type {
            stroke: $dark-grey;
        }
    }

    .profile-item + .profile-item {
        margin-top: $spacing;
    }
}

.settings-modal {
    overflow-y: scroll;
}

.stop-modal {
    &.modal-wrapper .modal-content {
        width: 30vw;
    }
    &-text {
        padding: $spacing;
    }

    .modal-submit-container {
        padding: 0 $spacing $spacing;
        justify-content: flex-end;

        .btn + .btn {
            margin-left: $spacing;
        }
    }
}

@media (max-width: $tablet-max-width) {
    .app-header {
        .side-panel-toggler {
            display: block;
            cursor: pointer;
        }
    }
}

.ace_editor {
    min-height: 150px;
}

.icon-copy {
    width: 19px;
    height: 19px;
    margin: 2px;
    opacity: 0.8;
    padding: 3px;
}

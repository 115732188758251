.connectivity-map-view {
    padding: $spacing;
    height: calc(100vh - 100px);

    &-title {
        display: inline;
        font-size: 16px;
        font-weight: 500;
        margin-right: 10px;
    }
    .chip {
        display: inline-flex;
        align-items: center;
        padding: 5px 15px;
        border: 1px solid $text-secondary;
        border-radius: 8px;
        margin: 5px;
        transition: all 0.3s;
        cursor: pointer;
    }

    .chip:hover {
        background: $gray-08;
    }

    .chip.active {
        background: $feeder-new;
        border: none;

        .checkmark {
            visibility: visible;
            color:  $icons-brown;
        }
    }

    .checkmark {
        visibility: hidden;
    }

    .title-button {
        border: none;
        background: none;
        cursor: pointer;
    }

    .delete-button {
        background: none;
        border: none;
        color: $icons-brown;
        cursor: pointer;
        visibility: hidden;
        width: 15px;
        height: 15px;
    }

    .chip:hover .delete-button {
        visibility: visible;
        justify-content: center;
        align-items: center;
    }

    &-checkboxes {
        display: flex;
        align-items: center;
        margin-bottom: $spacing;
        font-weight: 700;

        .beewise-select {
            width: 500px;
            margin-right: 8px;
        }

        &-wrapper {
            display: flex;
            flex-wrap: wrap;
        }

        .checkbox {
            margin-right: 8px;

            label:before {
                margin-top: 1px;
            }
        }
    }

    .grid {
        height: calc(100vh - 250px);

        .status-renderer {
            position: relative;
            display: flex;
            align-items: center;
        }
        .bad,
        .good {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            margin-right: 10px;
        }
        .bad {
            background-color: $bad;
        }
        .good {
            background-color: $good;
        }
    }

    .lc-renderer {
        &-error {
            color: red;
        }
    }

    .note-renderer-cell {
        .ag-cell-wrapper,
        .ag-cell-value,
        .ag-react-container {
            width: 100%;
            display: flex;

            .text-field {
                padding: 0;
                font-size: 12px;
                line-height: 20px;
            }
        }

        .ui.fluid.input {
            width: 80%;
        }

        .ag-cell-value {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        .floppy-icon {
            font-size: 20px;
            margin-left: 4px;
            cursor: pointer;
            color: $gray;
        }

        .note-renderer-date {
            font-size: 10px;
            height: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            color: $gray;
            margin-left: 8px;
        }
    }

    .grid-search {
        display: flex;
        align-items: center;
        justify-content: center;

        .input {
            width: 100%;
        }

        .bulk-status-changer {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 8px;

            .beehome-select {
                min-width: 300px;
                width: 300px;
            }

            .status-picker {
                min-width: 135px;
                width: 155px;
            }

            .btn {
                margin-left: 8px;
            }
        }
    }

    .percent-block {
        display: flex;
        gap: 5px;
        flex-direction: column;
        font-size: 14px;

        .percent-bold {
            font-weight: bold;
        }
    }
}

.btn-block {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

@import '~@beewise/theme/build/variables';

$checkbox-size: 8px;
$icon-size: 10px;

$checkbox-size-small-screen: 6px;
$icon-size-small-screen: 8px;

$frame-min-width: 8px;

.station-wrapper {
    .station-header {
        margin-bottom: 8px;
    }
    .station {
        background-color: $white;
        box-sizing: border-box;
        max-height: 164px;
        height: 100%;
        min-height: 164px;
        display: flex;
        flex-direction: column;
        position: relative;
        min-width: 100%;

        &-content {
            display: flex;
            flex: 1;
        }

        &-hive {
            height: 10px;
            min-width: 8px;
            position: absolute;
            border-radius: 0 0 10px 10px !important;
            background: $icons-light-green;

            &-locked {
                background: $bad;
            }
        }

        &-hives-wrapper {
            position: relative;
            bottom: -4px;
            display: flex;
            width: 100%;

            .btn-gradient {
                background: linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 1) 44%,
                    rgba(235, 235, 245, 1) 61%,
                    rgba(196, 196, 196, 1) 100%
                );
            }
            .btn {
                padding: 0;
            }
        }

        &-frame {
            min-width: $frame-min-width;
            display: flex;
            flex-direction: column-reverse;
            background-color: $gray-30;
            height: 100%;
            cursor: pointer;

            &-wrapper {
                position: absolute;
                top: 0;
                height: 100%;
                margin: 0 1px 0 0;

                &-static-partition {
                    .station-frame {
                        min-width: calc($frame-min-width / 2);
                    }
                }

                .station-frame-checkbox.ui.fitted.checkbox.beewise-checkbox {
                    min-height: unset;
                    min-width: unset;
                    line-height: unset;
                    width: 10px;
                    height: $checkbox-size;
                    margin: 2px 0;
                }

                .ui.checkbox.beewise-checkbox input[type='checkbox'] {
                    width: $checkbox-size;
                    height: $checkbox-size;
                }

                .ui.checkbox.beewise-checkbox label:before {
                    width: $checkbox-size;
                    height: $checkbox-size;
                    background-color: unset;
                    border: 1px solid $gray-04;
                    border-radius: 2px;
                }

                .ui.checkbox.beewise-checkbox input[type='checkbox']:focus ~ label:before {
                    background-color: unset;
                    border: 1px solid $gray-04;
                    border-radius: 2px;
                }

                .ui.checkbox.beewise-checkbox input[type='checkbox']:checked ~ label:before {
                    background-color: $beewise-yellow;
                    border: 1px solid $beewise-yellow;
                    border-radius: 2px;
                }

                .ui.checkbox.beewise-checkbox input[type='checkbox']:checked ~ label:after {
                    width: $icon-size;
                    height: $icon-size;
                    font-size: 6px;
                    line-height: $icon-size;
                    color: $white;
                }

                .station-frame-exclamation-square {
                    width: $checkbox-size;
                    height: $checkbox-size;
                    color: $red;
                    cursor: pointer;
                }

                .station-frame-alert-icon {
                    font-size: 12px;
                    color: #cd1c00;
                    pointer-events: none;
                }

                .station-frame-status-icon {
                    font-size: 12px;
                    color: $gray-04;

                    &.with-checkbox {
                        top: -44px;
                    }

                    &.alerted {
                        path {
                            fill: $bad;
                        }
                    }

                    &.alerted + .station-frame-status-icon {
                        margin-top: 2px;
                    }
                }

                .frame-arrow {
                    position: absolute;
                    color: $gray-04;
                    bottom: -17px;
                    left: -1px;
                    background: white;
                    z-index: 1;
                    border: 1px solid $gray-04;
                    width: $checkbox-size;
                    height: $checkbox-size;
                    transform: rotate(45deg);
                    border-bottom: 0;
                    border-right: 0;
                }
            }

            &-empty {
                cursor: default;
            }

            &-selected {
                border: 1px solid $primary-blue;
                box-sizing: border-box;
            }

            @import 'src/components/reusables/BhomeLayout/components/Frame/FrameColors';
        }

        .station-frame-item-wrapper {
            width: 100%;
            position: relative;

            &-status {
                position: absolute;
                display: flex;
                flex-direction: column;
                transform: translateY(-100%);
                align-items: center;
                left: -1px;
                top: -4px;

                .fa-droplet {
                    margin-left: 1px;
                }
            }
        }

        .station-frame-status-icon {
            font-size: $icon-size;
            color: $gray-04;

            &.with-checkbox {
                top: -44px;
            }

            &.alerted {
                path {
                    fill: $bad;
                }
            }

            &.alerted + .hive-block-frame-status-icon {
                margin-top: 2px;
            }
        }
    }
}

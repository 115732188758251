.card.devices {
    height: 100%;
    grid-column: 1 / 3;

    .bold {
        font-weight: 700;
    }

    .small {
        font-size: 10px;
    }

    .card-content {
        display: flex;

        .devices-side {
            flex: 1;
        }

        .sim-content {
            display: flex;
            flex-direction: column;
            margin-top: 16px;
        }

        .devices-content {
            margin-top: 16px;
        }
    }
}

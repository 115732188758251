.frame-actions-center-v4 {
    .open-hive-params {
        display: flex;
        align-items: center;
        gap: 10px;

        .select-field-root,
        .input {
            margin-left: 10px;
        }

        .open-hive-destination {
            margin-left: 10px;
        }
    }

    .action-hive-params {
        .select-field-root,
        .input {
            margin-left: 4px;
        }

        .close-hive-section {
            display: flex;
            align-items: center;

            .bhome-layout-wrapper {
                margin-left: 4px;
            }
            .nowrap.frame-type-select.tuck-direction-select {
                width: 140px;
                min-width: 140px;
            }
        }
        .close-hive-subheader {
            margin-top: 10px;
        }
    }
    .scan-frame-params, .partial-frame-layout-params {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
    }

    .nowrap {
        min-height: auto;
        min-width: 150px;
        width: auto;
    }
}

@import '~@beewise/theme/build/variables';

.current-users {
    position: fixed;
    background: $white;
    bottom: 0;
    right: 0;
    border: 1px solid $grey-5;
    border-bottom: 0;
    border-radius: 8px;
    padding: 16px 18px 14px 12px;
    z-index: 1;

    &-header {
        color: $grey-2;
        font-weight: 500;
        display: flex;

        &-title {
            cursor: pointer;
            line-height: 18px;

            &:hover {
                color: $black;
                transition: color 0.3s ease-in-out;
            }
        }

        .icon {
            margin-left: 8px;
            cursor: pointer;
            width: 18px;
            height: 18px;
            transform: rotate(180deg);
            transition: transform 0.3s ease-in-out;
            color: $grey-2;
            transform-style: preserve-3d;

            &:hover {
                color: $grey-1;
                cursor: pointer;

                &:before {
                    content: '';
                    width: 24px;
                    height: 24px;
                    margin-left: -3px;
                    margin-top: -3px;
                    opacity: 0.6;
                    position: absolute;
                    background-color: $grey-6;
                    border-radius: 50%;
                    transform: translateZ(-1px);
                }
            }

            svg {
                width: 18px;
                height: 18px;
            }
        }

        &:before {
            content: '';
            height: 12px;
            width: 12px;
            background: $good;
            border-radius: 50%;
            margin-top: 2px;
            margin-right: 8px;
        }
    }

    &-body {
        transform: scaleY(0);
        max-height: 0;
        transform-origin: top;
        transition: transform 0.1s ease-in-out, max-height 0.1s ease-in-out;
        overflow: hidden;
    }

    &.open {
        .current-users-header {
            border-bottom: 1px solid $grey-5;
            padding-bottom: 8px;

            .icon {
                transform: rotate(0);
                transition: transform 0.3s ease-in-out;
            }
        }

        .current-users-body {
            padding-top: 8px;
            transform: scaleY(1);
            transform-origin: top;
            max-height: 500px;
            transition: transform 0.3s ease-in-out, max-height 0.3s ease-in-out;

            .current-user {
                padding: 8px 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
}

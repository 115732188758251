.live-video {
    height: 100%;
    position: relative;

    &-bhome-id {
        position: absolute;
        top: 16px;
        right: 16px;
    }

    .handlers {
        margin-bottom: 30px;
        display: flex;
        flex-wrap: wrap;
        text-align: left;

        .live-video-id-select {
            min-width: 90px;
            width: 90px;
        }

        .live-video-type-select {
            min-width: 150px;
            width: 150px;
        }

        .live-video-quality-select {
            min-width: 100px;
            width: 100px;
        }

        .select-field-root + .select-field-root {
            margin-left: 10px;
        }
    }

    > .card-content {
        display: flex;
        justify-content: center;
        flex-direction: column;

        .stream {
            display: flex;
            justify-content: center;
            iframe {
                height: 340px;
            }

            &-placeholder {
                height: 340px;
            }
        }

        &:first-child {
            margin-right: 8px;
        }
    }

    .btn-primary {
        position: absolute;
        top: 10px;
        right: 20px;
    }
}

.live-feed {
    width: 100%;
    text-align: right;

    .label {
        text-align: center;
    }

    iframe {
        padding-top: 18px;
    }

    .beewise-checkbox {
        margin-bottom: 10px;
    }
    .stream {
        display: flex;
        justify-content: center;
        position: relative;

        button {
            position: absolute;
            top: -26px;
            left: 0;
            background-color: $green;
            width: 60px;
            font-size: 10px;
            padding: 3px;
        }
        &-placeholder {
            height: 310px;
        }

        video {
            width: 100%;
        }
    }

    &:first-child {
        margin-right: 8px;
    }
}

.config {
    width: 100%;
    background-color: $grey;
    min-height: calc(100vh - $app-header-height);
    padding: $spacing;
    box-sizing: border-box;

    &-mode-switch {
        margin-bottom: $spacing;

        & + & {
            margin-left: $spacing;
        }
    }

    &-content {
        @extend .white-box;
        padding: $double-spacing $spacing $spacing;

        .input {
            width: 25%;
            margin-bottom: $double-spacing;
        }

        .btn {
            margin-top: $spacing;
        }

        .jsoneditor {
            height: 50vh;
        }
    }

    &-content + &-content {
        margin-top: $spacing;
    }

    .config-blog {
        display: flex;
        margin-bottom: 5px;
    }

    &-comment {
        margin-left: $spacing;
        display: flex;
    }

    &-global {
        &-title {
            margin-bottom: $spacing;
        }

        .btn {
            margin-top: $spacing;
        }

        .jsoneditor {
            height: 60vh;
        }

        .jsoneditor-remove {
            display: none;
        }

        .jsoneditor-field {
            pointer-events: none;
        }

        .jsoneditor-tree {
            background-color: $white;
        }
    }

    .error {
        color: $bad;
        margin-bottom: $spacing;
        font-size: 18px;
    }
}

.conflicts {
    &-body {
        padding: $spacing;
        box-sizing: border-box;
    }

    &-title {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: $spacing;
    }

    &-list {
        max-height: 300px;
        overflow-y: auto;
    }

    &-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &-bold {
            font-weight: 500;
            font-size: 16px;
        }

        &-checkbox,
        &-id,
        &-name,
        &-value {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        &-id {
            width: 100px;
            margin-left: $spacing;
        }

        &-name {
            width: 150px;
            margin-left: $spacing;
        }

        &-value {
            width: 400px;
            margin-left: $spacing;
        }
    }
}

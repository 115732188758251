@import '~@beewise/theme/build/variables';

.sequences-modal {
  .ag-theme-material .no-border.ag-cell:focus {
    border: none;
    outline: none;
  }

  .icon-copy {
    position: absolute;
    right: 0;
    padding: 0 5px;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 5px;
    cursor: pointer;
    background-color: $white;
  }
}


@import '~@beewise/theme/build/variables';

.card {
    background: $white;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    height: fit-content;
    border-radius: 8px;
    position: relative;

    &-header {
        font-size: 16px;
        padding-bottom: 30px;

        &-expandable {
            cursor: pointer;
            user-select: none;
        }
    }

    .toggle-button {
        position: absolute;
        right: 20px;
        cursor: pointer;
        user-select: none;
        font-size: 16px;
    }

    &-content {
        display: flex;
        justify-content: space-between;

        .label {
            padding-bottom: 16px;

            .strong {
                font-weight: bold;
            }
        }
    }
}

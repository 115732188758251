@import '~@beewise/theme/build/variables';

.image-placeholder {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    background-color: $light-grey;
    border-radius: 8px;

    &-icon {
        transform: scale(1.3);

        svg {
            stroke: $dark-grey;
        }
    }

    &-text {
        padding-top: 16px;
        font-size: 18px;
        color: $dark-grey;
        pointer-events: none;
    }
}

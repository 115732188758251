@import '~@beewise/theme';
.ag-custom-tooltip {
  width: 200px;
  height: auto;
  overflow: auto;
  color: $text-primary;
  background-color: $white;

  p {
    margin: 5px;
    white-space: nowrap;
  }

  span {
    font-weight: bold;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: $text-primary;
    margin: 4px 0 ;
  }
}


@import '~@beewise/theme/build/variables';

.bhome-layout-modal.modal-wrapper {
    .modal-content {
        width: 80%;
    }

    .modal-body {
        .bhome-layout-content {
            padding: $spacing;
            .bhome-layout-update {
                top: 24px;
            }
        }
    }

    .bhome-layout-modal-done {
        position: absolute;
        bottom: 12px;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        cursor: pointer;
    }
}

.bhome-layout-content {
    position: relative;

    .text-warning {
        color: $road-block-red;
        font-weight: 500;
    }

    .bhome-layout-update {
        position: absolute;
        top: 10px;
        left: $double-spacing;
        display: flex;
        align-items: center;
        gap: 8px;

        .btn {
            height: 14px;
        }
    }
    .hive-stations-line + .hive-stations-line {
        margin-top: $spacing;
    }
    .hive-stations-content {
        box-sizing: border-box;
        width: 100%;
        background: $white;
        box-shadow: 0 4px 16px 8px rgba(0, 0, 0, 0.05);
        border-radius: 20px;
        padding: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .hive-stations-lines {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100%;
    }

    .hive-stations-frames {
        width: 100%;
        margin: 0 32px;
    }

    .hive-stations-line {
        display: flex;
    }

    .hive-stations-name {
        min-height: 160px;
        color: $text-primary;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 500;
    }

    .hive-syrup-container {
        min-height: 348px;
        min-width: 20px;
        max-width: 20px;
        background-color: $gray-30;
        writing-mode: vertical-rl;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $text-primary;
        font-size: 14px;
        position: relative;
    }

    .hive-syrup-level {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 20px;
        box-sizing: border-box;
        background-color: $feeder-new;
    }

    .hive-syrup-text {
        z-index: 1;
    }
}

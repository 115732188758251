.special-features {
    background-color: $grey;
    padding: $spacing;
    box-sizing: border-box;
    width: 100%;

    &-item {
        display: flex;

        .tech-button {
            margin-left: $spacing;
        }
    }

    .content-info {
        margin: 10px 0;
    }

    .flex-line {
        display: flex;
        align-items: center;
        justify-content: center;

        &-left {
            justify-content: flex-start;
        }

        .btn + .btn {
            margin: 0 0 0 10px;
        }

        .select-field-root + .btn {
            margin: 0 0 0 10px;
        }
    }

    &-content {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 24px;
        margin-top: $spacing;
    }

    .frame-scale-center {
        grid-row: 1;
        grid-column: 3 / 5;
    }
    .beehome-logs-center {
        grid-row: 2 / 4;
        grid-column: 1 / 5;
        display: flex;
    }
    .movement-calibration-center {
        grid-row: 2;
        grid-column: 5 / 8;
    }
    .entrances-actions-center {
        grid-row: 4;
        grid-column: 1 / 5;
    }
    .test-actions-center {
        grid-row: 4;
        grid-column: 5 / 8;
    }

    &-v4 {
        .movement-calibration-center {
            grid-row: 1;
            grid-column: 1 / 3;
        }
        .test-actions-center {
            grid-row: 1;
            grid-column: 3 / 5;
        }
    }

    .entrance-actions {
        .card-content {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 10px;
            justify-content: flex-start;
            align-items: center;
        }
    }

    .test-actions {
        height: 100%;

        .test-actions-content {
            display: flex;

            .btn + .btn {
                margin-left: $spacing;
            }
        }
    }

    .feeder-actions {
        height: 100%;

        .feeder-actions-content {
            display: flex;
            flex-direction: column;

            .btn + .btn {
                margin-left: $spacing;
            }

            .feeder-actions-item {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .checkbox {
                    margin-left: $spacing;
                }
            }

            .feeder-actions-item + .feeder-actions-item {
                margin-top: 10px;
            }
        }
    }

    .beehome-logs-center {
        display: flex;

        .bhome-logs {
            height: 100%;
            position: relative;
            width: 100%;

            .card-content {
                flex-flow: column nowrap;
            }

            &-range {
                margin-left: $spacing;

                .field + .field {
                    margin-top: 2px;
                }
            }

            .btn-error {
                background-color: $bad;
            }

            &-error {
                color: $bad;
                margin-bottom: $spacing;
                font-size: 16px;
            }

            &-controls {
                position: absolute;
                top: 7px;
                left: 150px;
                display: flex;
                align-items: center;

                &-range {
                    top: -11px;
                }

                .btn {
                    margin: 0 0 0 $spacing;
                    width: auto;
                    padding: 11px 12px;
                }

                .btn.get-logs {
                    margin: 0 0 0 $spacing;
                }

                .select-field-root {
                    min-width: 130px;

                    &__menu {
                        min-width: 130px;
                    }
                }
                .select-field-root + .select-title {
                    margin-left: $spacing;
                }
                .select-title {
                    margin-right: 5px;
                    color: $grey-1;
                }
            }

            .logs-content {
                width: 100%;
                min-height: 350px;
                background-color: $grey-1;
                border-radius: 4px;
                box-sizing: border-box;
                padding-top: 10px;

                & > div > div > a {
                    cursor: pointer;
                }
            }

            .btn.stop-log-stream {
                margin-left: $spacing;
                color: $white;
            }

            .clear-button {
                margin-top: $spacing;
            }
        }
        .logs-section {
            margin-bottom: $spacing;
        }
        .download-file {
            .text-field-root {
                margin-right: $spacing;
            }
        }
    }

    .read-rfid {
        .card-header {
            padding-bottom: 0;
        }
    }

    .calibration-movement {
        height: 100%;

        .btn + .btn {
            margin-top: 10px;
        }
    }

    .rfid-reader {
        .content-subinfo + .content-subinfo {
            margin-top: 10px;
        }

        .strong {
            display: block;
            margin-top: 3px;
        }
        .rfid {
            margin-top: 10px;
        }
    }
    .centrifuge {
        height: 100%;

        .rotate-fast {
            margin-top: 10px;
        }

        .line-buttons {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;

            .tech-button + .tech-button {
                margin: 0 0 0 10px;
            }

            .beewise-checkbox {
                white-space: nowrap;
                flex: 40%;
            }

            .beewise-checkbox + .tech-button {
                flex: 40%;
            }
        }

        .btn + .btn,
        .frames-slot-info,
        .frames-slot-info + .btn {
            margin-top: 10px;
        }

        .ui.label {
            background-color: $light-blue;
        }

        .harvest-item {
            display: flex;
            align-items: center;
            margin-top: 5px;

            .btn {
                margin-left: 10px;
            }
        }

        .harvest-list {
            margin-top: 5px;
        }

        .harvest-item:last-of-type {
            margin-bottom: 5px;
        }

        .harvest-slot-picker {
            display: flex;
            align-items: center;

            .btn {
                margin-left: 10px;
            }

            .ui.dropdown.multiple {
                min-width: 160px;
            }
        }
    }
}

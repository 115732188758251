.signin {
    @include auth-form;

    &-button {
        padding: 8px 12px;
        margin-top: 20px;
    }

    &-form {
        width: 318px;
    }

    .btn-primary {
        justify-content: center;
    }

    .forgot-password {
        @extend .white-box;
        margin-top: $spacing;
        padding: $spacing;
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        width: 360px;
        line-height: 20px;

        &-icon {
            path {
                stroke: $good;
            }
        }

        &-bold {
            font-weight: 700;
        }

        &-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            & > div:not(.icon) {
                display: flex;
                font-size: 14px;
                color: $black;
                align-items: flex-start;
                width: 100%;
            }
        }

        &-resend {
            display: inline;
            background-color: transparent;
            border: none;
            padding: 0;
            margin: 0;
            font-size: 14px;
            color: $light-blue;
        }

        &-margin {
            margin-top: $spacing;
        }

        &-tip {
            flex-direction: column;

            & > div {
                color: $dark-grey;
            }
        }
    }

    .input-password {
        margin-top: 50px;
    }
}

.slots-multipicker {
    display: flex;
    flex-direction: column;
    margin-top: $spacing;

    .multipicker-header + .multipicker-selects {
        margin-top: 10px;
    }

    .multipicker-selects {
        display: flex;
        align-items: center;

        .frames-slot-info {
            margin: 0 10px;
        }
    }

    .ui.selection.dropdown {
        min-width: 100px;
    }

    .frames-list {
        margin-top: 10px;

        .frames-list-item {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .btn {
                margin-left: 10px;
            }
        }
    }

    .slot-select {
        margin: 0 10px;

        &.ui.selection.dropdown {
            min-height: auto;
        }
    }
}

.slots-multipicker-v4 {
    &-list {
        display: flex;
        flex-direction: column;

        &-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }
}

@import '~@beewise/theme/build/variables';

.pending-tasks {
    display: flex;
    margin-right: 20px;
    padding-right: 20px;
    border-right: 1px solid $dark-grey;

    .btn + .btn {
        margin-left: 10px;
    }

    .btn {
        padding: 10px 12px;
    }
}

.statistics {
    padding: $spacing;

    &-content {
        padding: $spacing;
        background-color: $white;
    }

    &-action {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-switch {
        margin: 0 10px;
    }

    &-range {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin: 0;

        .ui.multiple.selection {
            max-width: 500px;
        }
    }

    .custom-tooltip {
        background-color: $grey-1;
        box-shadow: 0 0 0 rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
        border-radius: 4px;
        padding: 8px;
        transform: translate(-55%, -100%);
        margin-top: -15px;

        &:before {
            content: ' ';
            background-color: $grey-1;
            position: absolute;
            width: 10px;
            height: 10px;
            bottom: -4px;
            transform: rotate(45deg);
            margin-left: -5px;
            left: 50%;
            top: auto;
            right: auto;
        }

        .content {
            color: $white;
            text-transform: capitalize;
        }
    }

    .csv-link {
        text-decoration: none;
        margin-right: $spacing;
        background-color: $grey-8;
        padding: 8px 12px;
        border-radius: 4px;
        color: $kona-grey;
    }

    .hidden {
        opacity: 0;
    }

    .recharts-rectangle.recharts-tooltip-cursor {
        fill: transparent;
    }

    .react-datepicker-wrapper {
        margin: 0 5px;

        .datetime {
            border: 1px solid #aeaeae;
            border-radius: 4px;
            box-shadow: inset 0 2px 2px #e9e9e9;
            font-size: 13px;
            line-height: 16px;
        }
    }
}

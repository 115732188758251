.script-executor {
    padding: $spacing;

    &-controls {
        display: flex;

        .btn + .btn {
            margin-left: $spacing;
        }
    }

    &-content {
        margin-top: $spacing;

        .ui.selection.dropdown {
            min-width: 500px;
        }
    }

    &-script {
        display: flex;
        flex-direction: column;
        margin-top: $spacing;
    }

    &-argument {
        display: flex;
        align-items: center;

        & + & {
            margin-top: $spacing;
        }

        &-name {
            min-width: 200px;
        }

        &-form {
            display: flex;
            flex-direction: column;
            position: relative;
        }

        &-input {
            min-width: 400px;
        }

        &-description {
            position: absolute;
            bottom: -14px;
            left: 4px;
            font-size: 12px;
            color: $gray-08;
        }
    }

    &-send {
        width: 200px;
        margin-top: $spacing;
    }

    &-log-info {
        margin-top: $spacing;
    }

    &-logs {
        margin-top: $spacing;
        background-color: $grey-1;
    }

    .bold {
        font-weight: 700;
    }

    .jenkins-scripts-wrapper {
        display: flex;
        flex-direction: column;

        .jenkins-scripts-item {
            display: flex;
            margin-bottom: $spacing;

            .btn {
                margin-left: $spacing;
            }

            .select-field-root {
                min-width: 335px;
            }

            .text-field-root {
                margin-left: $spacing;
            }
        }
    }
    .jenkins-results {
        .icon-sync {
            position: absolute;
            top: 100px;
        }

        .grid {
            height: 60vh;
            margin-bottom: $spacing;

            .row-failure {
                background: #fcded9;
            }

            .row-success {
                background: #d4e6c8;
            }
        }
    }
}

.jenkins-s3-modal {
    .modal-content {
        width: 90vw;

        .grid {
            height: 70vh;
        }
    }

    .modal-body {
        padding: 0 5px 5px;
        max-height: 90vh;
        overflow: auto;
        .react-tabs__tab-list {
            margin: 0;
        }

        .jenkins-image-result {
            max-width: 100%;
        }
    }
}

.hives-view {
  padding: $spacing;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  gap: 10px;

  .hives-toggler {
    display: flex;
    gap: 10px;

    .bhome-select {
      min-width: 180px;
    }
    .hives-select {
      min-width: 500px;
      max-width: 500px;
    }
    .status-select {
      min-width: 130px;
    }
  }
}

.global-app-alert {
    position: fixed;
    top: unset;
    bottom: 15%;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 500;
    z-index: 2;
    background-color: $bad;
    border-radius: 4px;
    color: $white;
    font-size: 18px;
    padding: 24px;
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 98vw;
    box-sizing: border-box;

    &-item {
        word-wrap: break-word;
        width: 100%;
    }

    &-item + &-item {
        margin-top: $spacing;
    }
}

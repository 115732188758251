.power-management {
    padding: 15px;

    .react-datepicker__input-container {
        height: 100%;

        input {
            height: 100%;
            box-sizing: border-box;
            border: 1px solid rgba(34, 36, 38, 0.15);
            border-radius: 4px;
            min-width: 162px;
        }
    }
}

.rfid-history {
    padding: $spacing;

    .icon-sync {
        margin-bottom: $spacing;
    }

    &-errors.grid,
    &-disabled-hives.grid {
        height: 30vh;
        margin: $spacing;
    }

    &-errors,
    &-disabled-hives-header,
    .grid-csv {
        margin-top: 20px;
    }

    .jsoneditor {
        margin-top: $spacing;
        height: 30vh;
    }

    &-bold {
        font-weight: 700;
        margin-top: 5px;
    }

    &-layout {
        display: flex;
        gap: $spacing;

        &-list,
        &-lost-qrid {
            flex: 1;
        }

        .grid {
            margin-top: $spacing;
            height: 30vh;
        }
    }
}

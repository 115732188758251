.frames-view {
    padding: $spacing;

    .frames-content,
    .create-frame {
        background-color: $white;
        padding: 10px;
        margin-top: $spacing;

        .frames-rfid {
            margin-top: $spacing;
            display: flex;
            align-items: center;
        }

        .btn {
            margin-left: $spacing;
        }
    }

    .frames-actions {
        padding: $spacing;
        background-color: $white;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: $spacing 0;

        &-line {
            display: flex;
            align-items: center;
        }

        .frames-actions-line {
            .btn + .btn {
                margin-left: $spacing;
            }
        }

        .frames-actions-line + .frames-actions-line {
            margin-top: $spacing;
        }
    }

    .frames-form {
        margin-top: $spacing;
        background-color: $white;
        padding: 10px;
        position: relative;

        .select-field-root,
        .input {
            margin-right: 10px;
        }

        .frameid-input {
            min-width: 400px;
        }

        .header {
            margin-bottom: 10px;
        }
    }

    .frames-slot {
        display: flex;
        margin-top: $spacing;
        .select-field-root + .select-field-root {
            margin-left: 10px;
        }
    }

    .section-header {
        margin-top: $spacing;
    }

    .create-frame {
        margin-top: $spacing;
        display: flex;
        align-items: center;

        .select-field-root {
            margin-left: $spacing;
        }

        .rfid-input {
            input {
                width: 150px;
            }
        }

        .position-input {
            margin-left: $spacing;

            input {
                width: 100px;
            }
        }
    }

    .create-error {
        color: $bad;
        margin-bottom: $spacing;
    }

    .error {
        margin-top: $spacing;
        color: $bad;
    }

    .bad-rfid {
        &-header {
            display: flex;
            align-items: baseline;
        }

        &-time {
            margin-left: $spacing;
            color: $grey-4;
            font-size: 14px;
        }

        &-image {
            margin-top: $spacing;
        }
    }

    .frames-slot-index-data {
        position: absolute;
        top: 10px;
        left: 100px;
        color: $grey-4;
    }

    .frames-sides {
        margin-top: 10px;
        display: flex;
        width: 100%;
    }

    .frames-side {
        width: 50%;
    }

    .frames-side + .frames-side {
        margin-left: 10px;
    }

    .frames-textarea {
        width: 100%;
        height: 100px;
        resize: none;
    }

    .btn-success {
        background-color: $green;
    }

    .btn-error {
        background-color: $red;
        margin-left: 20px;
    }

    .edit-submit-buttons {
        display: flex;
        margin-top: $spacing;

        .btn + .btn {
            margin-left: $spacing;
        }
    }

    .enable-hives {
        margin-top: $spacing;
        background-color: $white;
        padding: 10px;
        display: flex;
        align-items: center;

        .beewise-checkbox {
            margin-left: 8px;
        }

        .btn {
            margin-left: $spacing;
        }
    }
}

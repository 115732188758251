.messages {
    padding: $spacing;
    height: calc(100vh - 100px);

    &-switch.checkbox {
        position: absolute;
        right: 50px;
        top: 25px;
        display: flex;
        align-items: center;
        margin-bottom: $spacing;

        span {
            display: flex;
            align-items: center;
        }
    }

    .icon-copy {
        position: absolute;
        right: 0;
        padding: 0 5px;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 5px;
        cursor: pointer;
        background-color: $white;
    }

    .ag-theme-material .no-border.ag-cell:focus {
        border: none;
        outline: none;
    }

    .icon-sync {
        position: absolute;
        top: 38px;
        transform: translateY(-50%);
        left: 170px;
    }

    .react-datepicker__input-container {
        height: 32px;
        position: absolute;
        top: 38px;
        transform: translateY(-50%);
        left: 214px;
        width: auto;

        input {
            height: 100%;
            border: 1px solid $gray-04;
            min-width: 164px;
        }
    }

    .messages-btn {
        position: absolute;
        top: 40px;
        transform: translateY(-50%);
        left: 390px;
        width: auto;
    }

    .sequence-cell-renderer {
        border: none;
        outline: none;
        display: flex;

        .sequence-cell-renderer-body {
            display: flex;
            text-wrap: wrap;
            height: 30px;
            font-size: 10px;
        }
    }
}

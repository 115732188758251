.sw-update-monitor {
    padding: $spacing;
    height: calc(100vh - 100px);

    &-date-wrapper {
        display: flex;
        align-items: center;
        gap: 15px;
        margin: $spacing;

        .react-datepicker__input-container {
            height: 32px;
            width: auto;

            input {
                height: 100%;
                border: 1px solid $gray-04;
                min-width: 164px;
            }
        }
    }
}

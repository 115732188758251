@import '~@beewise/theme/build/variables';

.activities {
    position: fixed;
    right: 0;
    top: 54px;
    width: 334px;
    height: calc(100vh - 54px);
    background-color: $off-white;
    box-shadow: 0 0 20px rgba(34, 36, 38, 0.15);

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
        height: 60px;

        .activities-title {
            font-size: 16px;
            font-weight: 700;
            color: $grey-2;
        }

        .btn {
            background-color: transparent;
        }
    }

    &-menu {
        display: flex;
        border-bottom: 2px solid rgba(34, 36, 38, 0.15);
        height: 46px;
        align-items: flex-end;

        &-item {
            color: $grey-2;
            padding: 12px 16px;
            cursor: pointer;

            &.menu-item-active {
                font-weight: 700;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    background-color: $grey-1;
                    left: 0;
                    bottom: -2px;
                }
            }
        }
    }

    &-body {
        padding: 24px 12px 16px 12px;
        color: $grey-1;

        .activities-count {
            margin-bottom: 16px;
        }

        .scrollbars {
            height: calc(100vh - 220px) !important;
        }

        .activity-card {
            width: 310px;
            height: 70px;
            border-radius: 8px;
            color: #222;
            background-color: #fff;
            margin-bottom: 8px;
            box-sizing: border-box;
            padding: 12px 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: 14px;

            &-details {
                font-size: 12px;
                color: $dark-grey;
            }
        }
    }
}

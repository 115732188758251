.grid {
    height: calc(100vh - 200px);

    &-header {
        display: flex;
        align-items: center;

        &.grid-header-without-search:not(.grid-header-with-actions) {
            display: none;
        }

        .text-field {
            width: 100%;
            padding: 8px;
            background-color: $white;
        }

        .input:not(:first-child),
        .btn:not(:first-child) {
            margin: 0 5px;
        }

        .input:first-child,
        .btn:first-child {
            margin-right: 5px;
        }

        .icon-sync {
            padding: 11px;
        }

        &-absolute {
            position: absolute;
        }

        &-without-search {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 80vw;
            height: 36px;
            margin: 20px auto;
        }
    }

    &-search {
        width: 50vw;
        height: 36px;
        margin: $spacing auto;

        .text-field {
            width: 100%;
            padding: 8px;
            background-color: $white;
        }

        .text-field-container {
            border-radius: 0;
        }
    }

    .ag-react-container {
        height: 100%;
        display: flex;
        align-items: center;
    }

    .grid-link-wrapper {
        cursor: pointer;
        text-decoration: underline;
        color: $link;
    }

    &-csv {
        height: 36px;
    }
}

.ag-row .ag-cell {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.ag-row .ag-cell:not(.ag-cell-wrap-text) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

@import '~@beewise/theme/build/variables';

.cameras-calibrations {
    background-color: $grey;
    padding: $spacing;

    .calibration {
        border: 1px solid $gray-80;
        margin-bottom: $spacing;
        padding: $spacing;

        h3 {
            font-weight: bold;
        }

        p {
            margin: 10px 0;
        }

        a {
            color: $primary-blue-active;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .image-slider {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            max-width: 100%;
            margin-bottom: 10px;
        }

        .slider-buttons {
            display: flex;
            gap: 10px;
        }
    }

    .success-text {
        color: $good;
    }
    
    .error-text {
        color: $bad;
    }
}

.layout-actions {
    padding: 20px;

    .create-placeholder-hive {
        margin: $spacing 0;
        display: flex;
        align-items: center;

        .select-field-root,
        .beewise-checkbox {
            margin-right: $spacing;
        }
    }

    .beehome-select {
        max-width: 30vw;
    }
}

.tech-button {
    &.green:not([disabled]) {
        background-color: $success;
        color: $white;
    }

    &.red:not([disabled]) {
        background-color: $bad;
        color: $white;
    }

    &.orange:not([disabled]) {
        background-color: $orange-1;
        color: $white;
    }

    &.fluid {
        display: block;
        width: 100%;
    }

    &[disabled] {
        cursor: default;
    }
}

.actions-monitoring {
    background-color: $grey;
    padding: $spacing;
    box-sizing: border-box;
    width: 100%;

    .bold {
        font-weight: 700;
    }

    &-content {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 24px;
    }

    .ui.selection.dropdown {
        min-height: auto;
    }

    .ui.selection.dropdown.nowrap {
        white-space: nowrap;
    }

    .ui.button {
        padding: 8px 8px;
        font-size: 12px;
    }

    .header-background,
    .left-column {
        font-weight: 700;
        background-color: $gray-20;
    }

    .header-disabled {
        color: $gray-40;
        cursor: default;
    }

    .MuiDataGrid-footerContainer,
    .MuiDataGrid-columnSeparator,
    .searchable-grid-container .search-wrapper,
    .MuiDataGrid-columnSeparator--sideRight {
        display: none;
        visibility: hidden;
    }

    .searchable-grid-container .grid-wrapper {
        padding: 10px 0;
    }

    .command-sender {
        display: flex;
        margin-top: $spacing;
        align-items: center;
        justify-content: flex-start;

        &-inputs {
            display: flex;
            flex-direction: column;
            width: 50%;

            .input {
                margin-bottom: $spacing;
            }
        }

        .btn {
            width: 25%;
            margin-left: $spacing;
        }
    }

    .flex-line {
        display: flex;
        align-items: center;
        justify-content: center;

        &-left {
            justify-content: flex-start;
        }

        .btn + .btn {
            margin: 0 0 0 10px;
            padding: 6px 9px;
        }

        .select-field-root + .btn {
            margin: 0 0 0 10px;
        }
    }

    .flex-line + .flex-line {
        margin-left: 10px;
    }

    .general-functions {
        grid-row: 1 / 3;
        grid-column: 1 / 5;
    }
    .frame-actions-center {
        grid-row: 3;
        grid-column: 1 / 5;
    }
    .axis-controller-center {
        grid-row: 4;
        grid-column: 1 / 3;
    }
    .axis-sensors-center {
        grid-row: 4 / 8;
        grid-column: 3 / 5;
    }
    .robot-versions-center {
        grid-row: 5 / 8;
        grid-column: 1 / 3;
    }
    .devices-center {
        grid-row: 6;
        grid-column: 5 / 8;
    }
    .geo-readings-center {
        grid-row: 7;
        grid-column: 5 / 8;
    }

    .beehome-logs-center {
        display: flex;
    }

    .general-functions {
        .card {
            height: 100%;
        }

        .flex-line {
            min-height: 40px;
        }

        .no-left-margin {
            margin-left: 0;
        }

        .flex-line .right-margin {
            margin-right: 220px;
        }

        .left-margin {
            margin-left: 10px;
        }

        .ir-sensors-select {
            margin-left: 10px;
        }

        .ir-temperature-value {
            margin-left: 5px;
        }

        .syrup-pump-activate {
            margin-left: 10px;
        }
    }

    .software-update {
        &-content {
            display: flex;
            align-items: center;
            width: 100%;
            gap: 6px;

            .checkbox-label {
                font-size: 12px;
            }
        }

        &-checkboxes {
            display: flex;
            flex-direction: column;
        }
    }

    .power-data {
        position: relative;

        &-get-button {
            position: absolute;
            top: 8px;
            right: 8px;
        }

        &-content {
            display: flex;
            flex-direction: column;
        }
        &-bold {
            font-weight: 700;
        }
    }

    .geo-readings {
        position: relative;

        .geo-reading-get-gps {
            position: absolute;
            top: 8px;
            right: 8px;
        }

        .geo-readings-item + .geo-readings-item {
            margin-top: 8px;
        }
        &-item {
            &-title {
                font-weight: 700;
            }
            &-content {
                margin-left: 8px;
            }
        }
    }

    .frame-actions-center {
        position: relative;

        .card {
            height: 100%;
        }

        .select-field-root.additional-param {
            width: auto;
            max-width: 330px;
        }

        .select-field-root.hive-slot-select {
            min-width: 135px;
            max-width: 135px;
        }

        .frames-actions-line {
            display: flex;
            position: absolute;
            top: 8px;
            right: 8px;
            align-items: center;

            .btn + .btn {
                margin-left: $spacing;
            }
        }

        .select-field-root.frame-type-select {
            min-width: 170px;
        }
    }

    .strong {
        font-weight: 700;
    }

    .content-info {
        margin: 10px 0;
    }

    .shaker-center {
        .card-content {
            display: flex;
            gap: 10px;
        }
    }

    .card {
        &.initialize,
        &.status-movement,
        &.reset,
        &.calibration,
        &.read-rfid,
        &.rfid-reader,
        &.calibration-movement,
        &.centrifuge {
            .card-header {
                padding-bottom: 0;
            }
        }

        &.actions {
            display: flex;
            flex-direction: column;
            .card-content {
                display: flex;
                flex-direction: column;
            }

            .actions-flex-line {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 10px;
                justify-content: flex-start;
                align-items: center;
            }
        }

        &.axis {
            width: 100%;
            > .card-content {
                display: flex;
                flex-direction: column;
            }

            .axis-controls {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 5px;

                .select-field-root {
                    min-width: 150px;
                    max-width: 160px;
                }

                .beewise-checkbox {
                    margin-left: 10px;
                }

                .distance-input input {
                    border-radius: 0;
                }

                .send {
                    margin-left: 10px;
                }
            }

            .axis-checkboxes {
                display: flex;
                flex-direction: column;
                margin-top: 30px;
            }

            .axis-distance {
                position: relative;
                display: flex;
                margin-left: 10px;
                max-width: 95px;

                .distance-input {
                    width: 100%;
                }

                .axis-plus,
                .axis-minus {
                    position: absolute;
                    width: 100%;
                    height: 25px;
                    border: 1px solid $grey-7;
                    outline: none;
                    cursor: pointer;

                    &.highlighted {
                        background-color: $gray;
                        color: $white;
                    }
                }
                .axis-plus {
                    top: -25px;
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                    border-bottom: 0;
                }
                .axis-minus {
                    bottom: -25px;
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
                    border-top: 0;
                }
            }
        }

        &.axes {
            height: 100%;
            position: relative;
            width: 100%;

            .card-content {
                flex-direction: column;
            }

            .axis-get-button {
                position: absolute;
                top: 8px;
                right: 8px;
            }

            tbody td {
                max-width: 60px;
                overflow: hidden;
                text-overflow: ellipsis;

                .open-hive-status {
                    font-weight: 700;
                }
            }

            .axes-table.ui.definition.table tr td:first-child {
                white-space: nowrap;
            }

            .sensors-toggler {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                cursor: pointer;

                .sensors-toggler-text {
                    margin-right: 10px;
                }
            }
        }

        &.robot-versions {
            height: 100%;
            position: relative;
            width: 100%;

            .card-content {
                width: 100%;
                .searchable-grid-container {
                    width: 100%;
                }
            }

            .robot-versions-get-button {
                position: absolute;
                top: 8px;
                right: 8px;
            }
        }
    }

    .lights-v4 {
        .card-content {
            margin-top: $spacing;
            display: flex;
            flex-direction: column;

            .send {
                margin-top: $spacing;
            }

            .lights-v4-selects {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                gap: $spacing;

                .select-field-root {
                    min-width: 160px;
                }
            }
        }
    }

    .camera-switch {
        position: relative;
        margin-top: $double-spacing;
        display: flex;
        flex-direction: column;

        .label {
            padding-bottom: 8px;
        }

        .select-field-root {
            margin-bottom: 10px;
        }

        .beewise-switch + .beewise-switch {
            margin-left: 16px;
        }

        .btn {
            margin-left: 20px;
        }

        .camera-settings {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .light-switch {
        width: fit-content;

        .beewise-switch {
            margin-top: 10px;

            .label {
                padding: 0;
            }
        }
    }

    .wrapped-line {
        display: flex;
        flex-wrap: wrap;
        gap: 3px;
        width: 100%;
    }

    .initialize {
        .margined {
            margin-top: 10px;
        }

        .camera-status {
            margin-bottom: 10px;

            .camera-status-item {
                margin-top: 5px;
            }

            .camera-status-header {
                font-weight: 700;
                font-size: 16px;
            }
        }
        .select-field-root {
            min-width: 120px;
        }
    }

    .actions {
        .btn {
            margin-left: 10px;
        }

        .select-field-root {
            min-width: 150px;
            width: auto;
        }
    }

    .camera-feed {
        position: relative;

        .card-header {
            padding-bottom: 15px;
            margin-bottom: 28px;
            width: 110px;
        }

        .card-content {
            justify-content: center;
        }

        .handlers {
            display: flex;
            position: absolute;
            top: 8px;
            right: 8px;

            .port-selection {
                padding-right: 16px;

                .ui.selection.dropdown.select-field-root {
                    min-width: 120px;
                }
            }

            .btn {
                margin-bottom: 4px;
                min-width: 100px;
            }
        }
    }
    .camera-view {
        padding: 20px;
    }

    .beewise-switch label {
        display: flex;
        align-items: center;
    }

    .axis-controller-center {
        display: flex;
        height: auto;

        .card + .card {
            margin-left: 10px;
        }

        .safe-for-travel {
            margin-top: $double-spacing;
            margin-bottom: $spacing;
        }
    }
    .video {
        justify-content: flex-start;

        &-search-wrapper {
            display: flex;
            align-items: center;

            .input {
                width: 100%;
            }

            .icon-sync {
                margin-left: $spacing;
            }
        }

        &-item {
            display: flex;
            align-items: center;
            padding: 4px 0;

            &-name {
                padding-right: 4px;
            }

            .button-reload {
                padding: 4px;
            }
        }

        video {
            align-self: center;
        }

        .download-icon {
            margin-left: 8px;
        }
    }

    .incubator-center {
        .incubator-center-buttons {
            display: flex;
            flex-direction: row;
            gap: 10px;
            flex-wrap: wrap;
        }
        .incubator-center-status-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 8px;
            margin-top: $spacing;
        }
        .incubator-center-status-item {
            display: flex;
        }
        .incubator-center-status-header {
            font-weight: 700;
            margin-right: 4px;
            white-space: nowrap;
        }
    }

    .activity-manager-center {
        .activity-manager .card-content {
            display: flex;
            flex-direction: column;
            gap: $spacing;

            .activity-manager-sides {
                display: flex;
                align-items: flex-start;
                gap: $spacing;
            }

            h3 {
                font-size: 16px;
                font-weight: 600;
            }

            .activity-manager-side {
                display: flex;
                flex-direction: column;
                gap: $spacing;
                flex: 1;
            }

            .activity-manager-line {
                display: flex;
                gap: $spacing;
                align-items: center;

                .activity-manager-item-status {
                    display: flex;
                    align-items: center;
                    gap: $spacing;
                }

                > *:first-child {
                    flex: 1;
                }
                > *:last-child {
                    flex: 2;
                }
            }
            .activity-manager-buttons {
                display: flex;
                gap: $spacing;
            }
            .activity-manager-save {
                width: 100px;
            }
        }
    }

    .ui.pagination.menu .item {
        min-width: auto;
    }

    .technician-button {
        margin-left: 10px;
        &-off {
            background-color: $bad;
        }
    }

    .frame-scale-center {
        .card-content {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .frame-scale-line {
                display: flex;
                gap: $spacing;
                width: 100%;

                .text-field-root {
                    width: 180px;
                }
            }
        }
    }

    &-v-4 {
        .general-functions {
            grid-row: 1;
            grid-column: 1 / 8;
        }
        .activity-manager-center {
            grid-row: 2;
            grid-column: 1 / 8;
        }
        .bhome-layout-content {
            grid-row: 3;
            grid-column: 1 / 8;
        }
        .frame-actions-center {
            grid-row: 4;
            grid-column: 1 / 8;
        }
        .hallway-video,
        .live-video-1 {
            grid-row: 5 / 7;
            grid-column: 5 / 8;
        }
        .axis-controller-center {
            grid-row: 5;
            grid-column: 1 / 3;
        }
        .axis-sensors-center {
            grid-row: 5 / 9;
            grid-column: 3 / 5;
        }
        .live-video-2 {
            grid-row: 7 / 9;
            grid-column: 5 / 8;
        }
        .robot-versions-center {
            grid-row: 6 / 9;
            grid-column: 1 / 3;
        }
        .geo-readings-center {
            grid-row: 9;
            grid-column: 1 / 3;
        }
        .lights-center {
            grid-row: 9 / 11;
            grid-column: 3 / 5;
        }
        .calibrations-center {
            grid-row: 9 / 11;
            grid-column: 5 / 8;
        }
        .devices-center {
            grid-row: 10;
            grid-column: 1 / 3;
        }
        .vents-center {
            grid-row: 11;
            grid-column: 1 / 3;
        }
        .shaker-center {
            grid-row: 11;
            grid-column: 3 / 5;
        }
        .software-update-center {
            grid-row: 11;
            grid-column: 5 / 8;
        }
        .power-data-center {
            grid-row: 12;
            grid-column: 1 / 3;
        }
        .incubator-center {
            grid-row: 12;
            grid-column: 3 / 8;
        }
        .frame-scale-center {
            grid-row: 13;
            grid-column: 1 / 4;
        }
        .general-functions {
            .flex-line .right-margin {
                margin-right: 10px;
            }
        }
    }

    &.actions-monitoring-manufacturer {
        .hide-for-manufacturer {
            display: none;
        }

        .light-switch {
            display: flex;

            .beewise-switch {
                margin-right: 10px;
            }
        }

        .general-functions {
            grid-row: 1;
            grid-column: 1 / 8;
        }

        .frame-actions-center {
            grid-row: 2;
            grid-column: 1 / 8;
        }
        .axis-controller-center {
            grid-row: 3;
        }
        .axis-sensors-center {
            grid-row: 3 / 7;
        }
        .robot-versions-center {
            grid-row: 4 / 7;
        }
        .devices-center {
            grid-row: 3;
            grid-column: 5 / 8;
        }
        .geo-readings-center {
            grid-row: 4;
            grid-column: 5 / 8;
        }
        .calibrations-center {
            grid-row: 7 / 9;
            grid-column: 3 / 5;
        }
        .lights-center {
            grid-row: 7 / 9;
            grid-column: 1 / 3;
        }
        .vents-center {
            grid-row: 7;
            grid-column: 5 / 8;
        }
        .shaker-center {
            grid-row: 8;
            grid-column: 5 / 8;
        }
        .software-update-center {
            grid-row: 9;
            grid-column: 1 / 3;
        }
    }

    &.actions-monitoring-em-tester {
        .actions-monitoring-content {
            & > div {
                display: none;
            }
            .frame-actions-center-v4,
            .axis-controller-center,
            .lights-center,
            .shaker-center,
            .axis-sensors-center {
                display: block;
            }
        }

        .frame-actions-center-v4 {
            grid-row: 1;
        }
        .axis-controller-center {
            grid-row: 2;
            grid-column: 1 / 4;
            min-height: 140px;

            .axis-distance {
                max-width: 180px;
            }
            .card {
                height: 100%;
            }
        }
        .lights-center {
            grid-row: 2;
            grid-column: 4 / 8;
        }
        .shaker-center {
            grid-row: 3;
            grid-column: 3 / 5;
        }
        .axis-sensors-center {
            grid-row: 3;
            grid-column: 1 / 3;
        }
    }

    &.actions-monitoring-gripper-tester {
        .actions-monitoring-content {
            > div {
                display: none;
            }
            .axis-controller-center,
            .axis-sensors-center {
                display: block;
            }

            .axis-controller-center {
                grid-row: 1;
                grid-column: 1 / 4;
            }
            .axis-sensors-center {
                grid-row: 1 / 3;
                grid-column: 4 / 8;
            }
        }

        .axis-controller-center {
            grid-row: 1;
            grid-column: 1 / 4;
            min-height: 140px;

            .axis-distance {
                max-width: 180px;
            }
            .card {
                height: 100%;
            }
        }
    }

    &.actions-monitoring-fl-tester {
        .actions-monitoring-content {
            > div {
                display: none;
            }
            .frame-actions-center-v4,
            .axis-controller-center,
            .lights-center,
            .robot-versions-center,
            .shaker-center,
            .axis-sensors-center,
            .devices-center,
            .general-functions {
                display: block;
            }
        }

        .frame-actions-center-v4 {
            grid-row: 1;
        }
        .axis-controller-center {
            grid-row: 2;
            grid-column: 1 / 4;
            min-height: 140px;

            .axis-distance {
                max-width: 180px;
            }
            .card {
                height: 100%;
            }
        }
        .lights-center {
            grid-row: 2;
            grid-column: 4 / 8;
        }
        .robot-versions-center {
            grid-row: 3 / 7;
            grid-column: 1 / 3;
        }
        .axis-sensors-center {
            grid-row: 3 / 7;
            grid-column: 3 / 5;
        }
        .shaker-center {
            grid-row: 3;
            grid-column: 5 / 8;
        }
        .devices-center {
            grid-row: 4;
            grid-column: 5 / 8;
        }
        .general-functions {
            grid-row: 5;
            grid-column: 5 / 8;

            .fl-tester-ir-sensor {
                display: flex;
                margin: 10px 0;
                gap: 10px;
            }
        }
    }

    &.actions-monitoring-ec-tester {
        .actions-monitoring-content {
            > div {
                display: none;
            }

            .robot-versions-center,
            .devices-center,
            .geo-readings-center,
            .vents-center,
            .software-update-center,
            .power-data-center,
            .axis-sensors-center {
                display: block;
            }
        }

        .robot-versions-center {
            grid-row: 1 / 3;
        }
        .devices-center {
            grid-row: 1 / 3;
            grid-column: 3 / 5;
        }
        .geo-readings-center {
            grid-row: 1;
            grid-column: 5 / 8;
        }
        .software-update-center {
            grid-row: 2;
            grid-column: 5 / 8;
        }
        .vents-center {
            grid-row: 3;
            grid-column: 1 / 3;
        }
        .axis-sensors-center {
            grid-row: 3 / 5;
            grid-column: 3 / 5;
        }
        .power-data-center {
            grid-row: 3;
            grid-column: 5 / 8;
        }
    }
}

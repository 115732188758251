@import '~@beewise/theme/build/variables';

.card.calibrations {
    .card-content {
        display: flex;
        flex-direction: column;

        h3 {
            margin-bottom: 10px;
        }
    }

    .calibrations-item + .calibrations-item {
        margin-top: 10px;
    }

    .calibrations-scale-download {
        display: flex;
        gap: 10px;
    }

    .calibrations-line {
        display: flex;
        flex-wrap: wrap;

        &.navigation-calibration {
            .select-field-root {
                min-width: 130px;
                margin-right: 10px;
            }
        }

        &.scan-calibration {
            margin-top: 10px;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;

            .select-field-root {
                min-width: 150px;
            }

            .frame-info {
                width: 100%;
                display: flex;
                gap: 4px;

                .text-field-root {
                    width: 60px;
                    min-width: 60px;
                }
            }
        }

        &.map-beehome {
            margin-top: 10px;
            .map-beehome-select {
                margin-right: 10px;
            }
        }

        &.fhtr-calibration {
            margin-top: 10px;
        }

        &-number {
            width: 12px;
            display: flex;
            align-items: center;
            margin-right: 4px;
        }
    }
    .calibrate-all {
        margin-top: 10px;
    }
}

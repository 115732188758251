@import '~react-datepicker/dist/react-datepicker.css';
@import '~react-tabs/style/react-tabs.scss';
@import '~@beewise/theme';

@import '~@beewise/icon/build/lib/Icon';
@import '~@beewise/button/build/lib/Button';
@import '~@beewise/modal/build/lib/Modal';
@import '~@beewise/side-menu/build/lib/SideMenu';
@import '~@beewise/toast/build/lib/Toast';
@import '~@beewise/beehome-status/build/lib/BeehomeStatus';
@import '~@beewise/tooltip/build/lib/Tooltip';

@import 'components/views/Config/Config';
@import 'components/views/ActionsAndMonitoring/ActionsAndMonitoring';
@import 'components/views/PasswordSetup/PasswordSetup';
@import 'components/views/SignIn/SignIn';
@import 'components/views/Frames/Frames';
@import 'components/views/CamerasCalibrations/CamerasCalibrations';
@import 'components/views/Statistics/Statistics';
@import 'components/views/Messages/Messages';
@import 'components/views/PowerManagement/PowerManagement';
@import 'components/views/RfidScanHistory/RfidScanHistory';
@import 'components/views/Monitoring/Monitoring';
@import 'components/views/ScriptExecutor/ScriptExecutor';
@import 'components/views/SpecialFeatures/SpecialFeatures';
@import 'components/views/LayoutActions/LayoutActions';
@import 'components/views/Errors/Errors';
@import 'components/reusables/TechButton/TechButton';
@import 'components/reusables/Grid/Grid';
@import 'components/reusables/SlotsMultipicker/SlotsMultipicker';
@import 'components/reusables/AppAlert/AppAlert';
@import 'components/reusables/LiveVideo/LiveVideo';
@import 'components/reusables/JsonEditor/editor.css';
@import 'components/views/SwUpdateMonitor/SwUpdateMonitor';
@import 'components/views/Hives/Hives';

#app {
    font-size: 14px;
    min-height: 100vh;
    font-family: Poppins, serif;

    h2 {
        font-size: 24px;
    }

    .functions-header {
        font-size: 16px;
    }

    #view {
        display: grid;
        grid-template-areas:
            'beehome-header aside'
            'main aside';
        grid-template-columns: 1fr auto;
        position: relative;
        min-height: calc(100vh - $app-header-height);
        margin-left: auto;
        width: calc(100vw - 250px);
        background-color: $grey;
        padding-top: $app-header-height;

        &.menu-hidden {
            width: calc(100vw - 56px);
        }

        &.no-menu {
            width: 100vw;
        }

        .beehome-header {
            grid-area: beehome-header;
        }

        .main {
            grid-area: main;
            background-color: $grey;
            min-height: calc(100vh - $app-header-height - 72px);
        }
    }

    #bee-toast-container {
        font-weight: 700;
        font-size: 14px;

        > div {
            width: auto;
        }

        .Toastify__toast {
            font-family: 'Poppins', serif;
            padding: $spacing 16px;
            border-radius: 8px;

            &--default {
                background: $blue;
                color: $white;
            }
        }
    }
}

.app {
    button {
        font-size: 13px;
        font-weight: 500;
    }

    .side-menu {
        top: $app-header-height;
        position: fixed;

        &__button-wrapper {
            height: 220px;

            .side-menu__button {
                align-self: flex-start;
                margin-top: 10px;
                z-index: 1;
            }
        }

        &__list {
            min-height: 100vh;

            &-item {
                height: 30px;
                .side-menu__list-item-link {
                    line-height: 30px;
                }
            }
        }
    }

    .side-menu-no-bhome {
        .side-menu__button-wrapper {
            height: 50px;
        }
    }

    .side-menu_opened,
    .side-menu_hovering {
        .beehome-containers {
            display: flex;
        }

        .side-menu-bhome-note {
            left: 24px;
            position: fixed;
            top: 64px;
            height: 199px;
            display: flex;
            align-items: center;

            .side-menu-bhome-note-wrapper {
                width: 170px;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .side-menu-bhome-note-textarea {
                width: 170px;
                height: 90%;
                resize: none;
                outline: none;
            }

            .side-menu-bhome-note-updated-at {
                font-size: 12px;
                margin-top: 8px;
            }

            .floppy-icon {
                font-size: 30px;
                margin-left: 8px;
                cursor: pointer;
                color: $gray;
            }
            .floppy-icon-disabled {
                cursor: default;
                opacity: 0.5;
            }
        }
    }

    .beehome-containers,
    .side-menu-bhome-note {
        display: none;
    }

    .no-bhome-view {
        padding: $spacing;
        font-size: 16px;
        font-weight: 500;
    }

    .view {
        width: 100%;
        padding: 12px;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 24px;

        &.row-view {
            grid-template-rows: 1fr auto;
        }
    }

    .ui.button {
        padding: 11px 12px;
        font-family: 'Poppins', serif;
    }

    .fat-image-downloader {
        margin-top: $spacing;

        .error {
            color: $red;
            margin-top: $spacing;
        }
    }
}

.icon-sync {
    padding: 10px;
    border-radius: 4px;
    background-color: $brand;
    color: $kona-grey;
    cursor: pointer;
}

.preloader-wrapper {
    position: relative;

    .preloader {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .spinner {
            path {
                stroke: $brand;
            }
        }
    }
}
